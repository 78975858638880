<template>
  <div class="backdrop">
    <div class="container modal">
      <p class="text-light">
        {{ input.message }}
        <router-link to="/datenschutz">Datenschutz</router-link>
      </p>

      <BaseButton variant="btn-tertiary" @click.native="accept()">{{
        input.accept
      }}</BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  methods: {
    accept() {
      this.$store.dispatch("acceptCookies");
      //localStorage.setItem("cookies", true);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: white;
  display: inline-flex;
  margin-left: 5px;
  font-weight: 400;
  text-decoration: none;
}
p {
  display: inline;
}
</style>
