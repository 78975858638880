<template>
  <div class="footer container">
    <ul v-if="items.length > 0">
      <li v-for="item in items" :key="item.name" class="navItems">
        <router-link :to="item">{{ item.label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>
