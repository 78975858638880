<template>
  <div>
    <nav :class="color">
      <div class="navContainer container">
        <div class="itemContainer">
          <ul v-if="items.length > 0">
            <li v-for="item in items" :key="item.label" class="navItems">
              <template v-if="item.path">
                <a :href="item.path" target="_blank">{{ item.label }}</a>
              </template>
              <template v-else>
                <router-link :to="item">{{ item.label }}</router-link>
              </template>
              
            </li>
            <li class="toggle">
              <a @click="triggerNav()" v-if="!active">
                <BaseIcon
                  icon="hamburger"
                  variant="light"
                  color="#ffffff"
                  width="30"
                  height="30"
                />
              </a>
              <a @click="triggerNav()" v-if="active">
                <BaseIcon
                  icon="close"
                  variant="light"
                  color="#ffffff"
                  width="30"
                  height="30"
                />
              </a>
            </li>
          </ul>
        </div>
        <div class="logoContainer">
          <ul>
            <li>
              <a
                class="ext-link"
                href="https://www.instagram.com/kickandquatsch"
                target="_blank"
                rel="”noopener”"
              >
                <span class="sr-only">Instagram Link</span>
                <BaseIcon
                  icon="instagram"
                  variant="circle"
                  color="#ffffff"
                  width="30"
                  height="30"
                />
              </a>
            </li>
            <li>
              <a
                class="ext-link"
                href="https://www.facebook.com/kickandquatsch"
                target="_blank"
                rel="noopener"
              >
                <span class="sr-only">Facebook Link</span>
                <BaseIcon
                  icon="facebook"
                  variant="circle"
                  color="#ffffff"
                  width="30"
                  height="30"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      v-if="type === 'foldout' && active == true"
      class="foldout"
      :class="color"
    >
      <div class="navContainer container">
        <ul>
          <li v-for="item in items" :key="item.label" @click="triggerNav()">
            <router-link :to="item">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="type === 'cover' && active == true" class="cover" :class="color">
      <div class="navContainer container">
        <ul>
          <li v-for="item in items" :key="item.label" @click="triggerNav()">
            <router-link :to="item">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "cover",
    },
    color: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    triggerNav() {
      if (this.active == true) {
        this.active = false;
      } else {
        this.active = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
