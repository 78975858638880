<template>
  <div id="folgen" class="folgen container">
    <h1 class="headline-dark">Podcast Folgen</h1>
    <iframe
      title="iTunes Podcast Liste"
      allow="autoplay *; encrypted-media *;
    fullscreen *"
      frameborder="0"
      style="width:100%;overflow:scroll;background:transparent;"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts
    
    allow-top-navigation-by-user-activation
    "
      src="https://embed.podcasts.apple.com/de/podcast/kick-and-quatsch/id1546702903?l=en"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>
