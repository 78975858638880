<template>
  <div class="container-dark" id="team">
    <div class="container">
      <h1 class="headline-light">Das Team</h1>
      <div class="team-images">
        <img
          src="../assets/kevin.webp"
          width="50%"
          height="50%"
          alt="Bild von Podcaster Kevin Lux"
        />
        <img
          width="50%"
          height="50%"
          src="../assets/oliver.webp"
          alt="Bild von Podcaster Oliver Kottwitz"
        />
      </div>
      <h2 class="subtitle-light">Wie ist die Idee entstanden</h2>
      <p class="text-light">
        Die Idee zum Podcast entstand durch die zwangsbedingte Lage aufgrund der
        Corona-Pandemie, dass wir unserem liebsten Hobby, dem aktiven Spielen
        und Gucken im Amateur-Fußball, leider nicht mehr nachgehen konnten. Der
        Kontakt zu Mitspielern, Trainer und Verantwortlichen wurde von Tag zu
        Tag immer weniger und man bekam auch nichts mehr aus den anderen
        Vereinen mit. Vielleicht gibt es in dem ein oder anderen Verein ein Zoom
        Training, Training auf Abstand oder vielleicht Aktionen die Vereine
        aktuell begleiten?! Somit kamen wir, Kevin Lux und Oliver Kottwitz,
        eines Tages im Ladenlokal von stickerfive zusammen und philosophierten
        über die aktuelle Lage. Wir haben über Fußball gesprochen und dem
        dazugehörigen Quatsch. Schnell war uns klar, dass was wir besprechen,
        müssen wir in einem Podcast aufnehmen. Der Name war schnell gefunden
        ‘’KICK AND QUATSCH’’, eine Abwandlung von „Kick and Rush”. Frei raus,
        ungeschnitten und echt - so wollen wir den Podcast gestalten.
      </p>
      <h2 class="subtitle-light">Wer wir sind</h2>
      <p class="text-light">
        Wir sind zwei Fußballverrückte Jungs Mitte 30 aus Oberhausen. Der eine
        stolzer Borussia Mönchengladbach Fan und der andere liebt den VfL
        Bochum. Unser Verein, in dem wir regelmäßig selbst aktiv kicken, ist die
        Spvgg. Sterkrade-Nord. Entsprechend oft sind wir auf den
        Kreisligaplätzen rund um Oberhausen unterwegs. Wenn nicht auf dem
        Spielfeld, dann mit einem Bierchen am Spielfeldrand.
      </p>
      <h2 class="subtitle-light">Ziel des Podcast</h2>
      <p class="text-light">
        Wir möchten mit unseren Gästen hinter die Kulissen blicken, über deren
        Tätigkeit sprechen und Einblick in den Verein erhalten. Wir möchten
        unseren Hörern einen lockeren Fußball-Plausch präsentieren, der den Kick
        in den Vordergrund stellt. Der Quatsch darf natürlich nicht fehlen und
        ist ein fester Bestandteil unseres Podcasts. Wir danken unseren
        Stammhörern und das regelmäßig tolle Feedback und freuen uns über jeden
        neuen Hörer. Euer KICK AND QUATSCH Team aus Oberhausen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>
