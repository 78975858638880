<template>
  <div class="container-dark" id="partner">
    <div class="container">
      <h1 class="headline-light">Unsere Partner</h1>
      <div class="partner-images">
        <img
          src="../assets/partner-stauder.png"
          alt="Logo von Stauder"
        />
        <img
          src="../assets/partner-sinalco.png"
          alt="Logo von Sinalco"
        />
        <img
          src="../assets/partner-matchday.png"
          alt="Logo von Matchday"
        />
        <img
          class="sinalco"
          src="../assets/partner-exact.png"
          alt="Logo von Exact"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.partner-images {
  margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
    img {
      height: 60px;
      width: 100%;
      object-fit: contain;
    }
}

</style>
