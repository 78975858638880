<template>
  <div id="kontakt">
    <div class="container">
      <h1 class="headline-dark">Kontakt</h1>
      <form @submit.prevent="submitForm()">
        <div class="fields">
          <div v-if="errors">
            <div v-for="error in errors" :key="error.type">
              <div class="alert" :class="error.type">
                {{ error.message }}
              </div>
            </div>
          </div>
          <div class="email">
            <p><label for="email">E-Mail</label></p>
            <input type="email" v-model="form.email" id="email" />
          </div>
          <div class="name">
            <p><label for="name">Name</label></p>
            <input type="text" v-model="form.name" id="name" />
          </div>
          <p><label for="message">Nachricht</label></p>
          <textarea
            v-model="form.message"
            id="message"
            cols="30"
            rows="10"
          ></textarea>
          <div class="form-checkbox">
            <input type="checkbox" required id="radio" /><label
              class="text-dark"
              for="radio"
              >Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich
              stimme zu, dass meine Angaben und Daten zur Beantwortung meiner
              Anfrage elektronisch erhoben und gespeichert werden.</label
            >
          </div>

          <div class="captcha">
            <vue-recaptcha
              sitekey="6LeIjEkaAAAAAC-Z4lLDIEOUjPAa81a4Ow9glETy"
              :loadRecaptchaScript="true"
            ></vue-recaptcha>
          </div>
          <BaseButton variant="btn-tertiary"
            ><span>Anfrage senden</span
            ><span v-if="loading"> ...</span></BaseButton
          >
          <div class="spacer"></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import emailjs from "emailjs-com";
import VueRecaptcha from "vue-recaptcha";
export default {
  components: {
    BaseButton,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: "",
      },
      errors: [],
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.errors = [];
      if (this.form.email && this.form.name && this.form.message) {
        this.loading = true;
        emailjs
          .send(
            "service_fpxvfqo",
            "template_4s42gj4",

            this.form
          )
          .then(() => {
            this.loading = false;
            this.errors.push({
              message: "Deine Anfrage wurde an uns übermittelt.",
              type: "success",
            });
          })
          .catch(() => {
            this.errors.push({
              message:
                "Leider gab es ein Problem bei der Übermittlung. Bitte versuche es später erneut.",
              type: "error",
            });
          });

        console.log("success");
      } else {
        this.errors.push({
          message: "Bitte überprüfe Deine Eingaben.",
          type: "error",
        });
      }
    },
  },
  mounted() {
    emailjs.init("user_vfZwGRefAZZUlfmtAaO9Y");
  },
};
</script>

<style>
iframe[title="recaptcha challenge"] {
  max-height: 100% !important;
  padding: 0;
}

.captcha {
  z-index: 8;
  position: relative;
}
button {
  position: relative;
  z-index: 10;
}
.spacer {
  margin-bottom: 235px;
}
</style>
