import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Impressum from '@/views/Impressum.vue';
import Datenschutz from '@/views/Datenschutz.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior (to, ) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: to.offset
      }
    }
  }
})

export default router
