<template>
  <div class="head-description">
    <div class="dark wrapper">
      <div class="container">
        <div class="logo">
          <p>
            <img
              src="@/assets/logo.webp"
              height="200px"
              width="200px"
              alt="Kick and Quatsch Logo"
            />
          </p>
        </div>
        <div>
          <p class="text-light">
            Der Fußball Podcast aus Oberhausen mit Kevin Lux und Oliver Kottwitz
          </p>
          <!--
          <div class="icon-nav">
            <a href="https://www.instagram.com/kickandquatsch" target="_blank">
              <BaseIcon
                icon="instagram"
                variant="circle"
                color="#ffffff"
                width="30"
                height="30"
              />
            </a>
            <a href="https://www.facebook.com/kickandquatsch" target="_blank">
              <BaseIcon
                icon="facebook"
                variant="circle"
                color="#ffffff"
                width="30"
                height="30"
              />
            </a>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import BaseIcon from "@/components/BaseIcon.vue";
export default {
  components: {
    //BaseIcon,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 699.9px) {
  .head-description {
    height: 50vh;
    background-image: url(../assets/background-1k.webp);
    .wrapper p {
      text-align: center;
      img {
        height: 150px;
        width: 150px;
        margin: 0 auto;
      }
    }
  }
}
@media (min-width: 700px) and (max-width: 999.9px) {
  .head-description {
    height: 45vh;
    background-image: url(../assets/background-1k.webp);
    .wrapper p {
      text-align: center;
      img {
        height: 200px;
        width: 200px;
        margin: 0 auto;
      }
    }
  }
}
@media (min-width: 1000px) and (max-width: 1919.9px) {
  .head-description {
    height: 45vh;
    background-image: url(../assets/background-2k.webp);
    .wrapper p {
      text-align: center;
      img {
        height: 200px;
        width: 200px;
        margin: 0 auto;
      }
    }
  }
}
@media (min-width: 1920px) {
  .head-description {
    height: 40vh;
    background-image: url(../assets/background-4k.webp);
    .wrapper p {
      text-align: center;
      img {
        height: 250px;
        width: 250px;
        margin: 0 auto;
      }
    }
  }
}
</style>
