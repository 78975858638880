<template>
  <button :class="variant" class="btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "btn-primary",
    },
  },
};
</script>

<style lang="scss" scoped></style>
