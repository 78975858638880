<template>
  <div id="app">
    <div v-if="!cookiesAccept">
      <Cookie :input="cookie" />
    </div>
    <div id="start">
      <BaseNavigation :items="navigation" type="cover" color="dark" />
      <Head />
    </div>
    <div class="content">
      <router-view />
    </div>
    <footer><Footer :items="footer" /></footer>
  </div>
</template>
<script>
import BaseNavigation from "@/components/BaseNavigation.vue";
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Cookie from "@/components/Cookie.vue";
import { mapState } from "vuex";
export default {
  components: {
    BaseNavigation,
    Head,
    Footer,
    Cookie,
  },
  data() {
    return {
      cookie: {
        message:
          "Diese Internetseite verwendet Cookies für die Analyse und Statistik. Durch die weitere Nutzung der Website stimmen Sie der Verwendung zu.",
        accept: "Akzeptieren",
        decline: "Ablehnen",
      },
      navigation: [
        {
          label: "Start",
          name: "Home",
          hash: "#start",
          offset: { x: 0, y: 10 },
        },
        {
          label: "Podcast Folgen",
          name: "Home",
          hash: "#folgen",
          offset: { x: 0, y: -50 },
        },
        {
          label: "Podcast Team",
          name: "Home",
          hash: "#team",
          offset: { x: 0, y: 10 },
        },
        {
          label: "Kontakt",
          name: "Home",
          hash: "#kontakt",
          offset: { x: 0, y: 10 },
        },
        {
          label: "Online-Shop",
          path: "https://kick-and-quatsch.stickerfive.de",
          offset: { x: 0, y: 10 },
        },
      ],
      footer: [
        { name: "Impressum", label: "Impressum" },
        { name: "Datenschutz", label: "Datenschutz" },
      ],
    };
  },
  computed: mapState(["cookiesAccept"]),
  mounted() {
    this.$store.dispatch("checkCookies");
  },
};
</script>
<style lang="scss">
@import "./scss/style";
</style>
