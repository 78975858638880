<template>
  <div>
    <Folgen />
    <Team />
    <Kontakt />
    <Partner />
  </div>
</template>

<script>
// @ is an alias to /src
import Folgen from "@/components/Folgen.vue";
import Team from "@/components/Team.vue";
import Kontakt from "@/components/Kontakt.vue";
import Partner from '@/components/Partner.vue';

export default {
  name: "Home",
  components: {
    Folgen,
    Team,
    Kontakt,
    Partner
  },
  data() {
    return {};
  },
  mounted() {
    var section = this.$router.currentRoute.hash.replace("#", "");
    if (section)
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );
  },
};
</script>
