import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cookiesAccept: false
  },
  mutations: {
    SET_COOKIES(state,value){
      state.cookiesAccept = value
    }
  },
  actions: {
    deleteCookies({commit}) {
      localStorage.setItem("cookies", false);
      commit('SET_COOKIES',false)
    },
    acceptCookies({commit}) {
      localStorage.setItem("cookies", true);
      commit('SET_COOKIES',true)
    },
    checkCookies({commit}){
      if(localStorage.getItem("cookies") === "true"){
        commit('SET_COOKIES',true)
      }else{
        commit('SET_COOKIES',false)
      }
    }
  },
  modules: {
  }
})
